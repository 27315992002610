import React, { useState, useEffect } from "react";
import Collapsible from "react-collapsible";
import "./index.css";

const OpeningHours = ({ item }) => {
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];
  const dayMappings = [
    { id: 0, day: "monday" },
    { id: 1, day: "tuesday" },
    { id: 2, day: "wednesday" },
    { id: 3, day: "thursday" },
    { id: 4, day: "friday" },
    { id: 5, day: "saturday" },
    { id: 6, day: "sunday" }
  ];

  const keysNames = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday"
  ];
  const { openingTime } = item.openingHours;
  const { opensAt, closesAt } = item.dispensaryTimings;
  const timeZone = item.timeZone;

  const [today, setToday] = useState({});

  useEffect(() => {
    if (openingTime) {
      const d = new Date();

      let dayID = d.getDay();
      let currentDay = dayMappings.find(
        singleDay => singleDay.id === dayID - 1
      );
      setToday(currentDay);
    }
  }, []);

  return (
    <div className="openingHoursDiv">
      {openingTime ? (
        <Collapsible
          trigger={
            openingTime[today?.day]
              ? `Opens today at ${openingTime[today?.day]} ${
                  timeZone ? timeZone : "PT"
                }`
              : "Today Closed"
          }
        >
          <div className="dispensaryHoursMainDiv">
            <div className="dispensaryHoursDays">
              {daysOfWeek.map(item => (
                <div className="dispensaryHourDay">{item}</div>
              ))}
            </div>
            <div>
              <div className="dispensaryHoursOpenTime">
                {keysNames.map(item => {
                  return opensAt[item] && closesAt[item] ? (
                    <div className="dispensaryHoursOpenAndCloseTime">{`${opensAt[item]} - ${closesAt[item]}`}</div>
                  ) : (
                    <div style={{ color: "#f66f6f" }}>Closed</div>
                  );
                })}
              </div>
            </div>
          </div>
        </Collapsible>
      ) : (
        <></>
      )}
    </div>
  );
};

export default OpeningHours;
